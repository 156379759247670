/* H2D */
.h2d #nav-wrap,
.h2d #nav {
  background: #252525;
}
.h2d #nav-wrap a.is-active,
.h2d #nav-wrap a.submenu.is-active,
.h2d #nav-wrap a:hover,
.h2d #nav-wrap li:hover a.submenu,
.h2d #nav-wrap li:hover span.submenu,
.h2d #nav-wrap span.submenu.is-active,
.h2d #nav-wrap ul.submenu a:hover,
.h2d #nav a.is-active,
.h2d #nav a.submenu.is-active,
.h2d #nav a:hover,
.h2d #nav li:hover a.submenu,
.h2d #nav li:hover span.submenu,
.h2d #nav span.submenu.is-active,
.h2d #nav ul.submenu a:hover {
  background: #0092c8;
}
.h2d #menutoggle {
  background-color: #252525;
}
.h2d #menutoggle.is-active, .h2d #menutoggle:active, .h2d #menutoggle:hover {
  background-color: #393939;
}
.h2d #nav ul {
  background: #393939;
}
.h2d #header #support a.login {
  background: #252525;
}
.h2d #header #support a.login:hover {
  background: #393939;
}
.h2d #banner-wrap {
  background: #fff url(/images/h2d/banner-bg-sm.jpg) no-repeat;
  background-size: cover;
}
.h2d #banner-wrap.alt {
  background: #fff url(/images/h2d/banner-bg-2-sm.jpg) no-repeat;
  background-size: cover;
}
.h2d #banner-wrap.start-smart {
  background: #fff url(/images/h2d/banner-bg-ss-sm.jpg) no-repeat 50% 50%;
  background-size: cover;
  min-height: 225px;
}
.h2d #banner-wrap.start-smart img {
  margin-top: 30px;
}
.h2d #banner-wrap p {
  max-width: 300px;
  font-size: 1.6em;
  color: #333;
  line-height: 1.4em;
}
.h2d #banner-wrap h4.parent-title {
  margin-top: -20px;
  color: #0f4b91;
  font-weight: 800;
  text-transform: uppercase;
}
.h2d #sale-wrap {
  background: #0594c9;
  font-size: 1.2em;
}
.h2d #cta-wrap {
  background: #252525;
}
.h2d #cta-wrap h1 {
  border-bottom: 1px solid #ccc;
  color: #fff;
}
.h2d #cta-wrap h2 {
  font-weight: bold;
  color: #ffc600;
  border-bottom: 0;
}
.h2d #cta-wrap .price {
  color: #ffc600;
}
.h2d #cta-wrap .price span.only.from {
  color: #fff;
  font-size: 16px !important;
  font-weight: 400;
}
.h2d #cta-wrap .price .member {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 8px;
}
.h2d h1,
.h2d h2,
.h2d h3,
.h2d h4 {
  color: #0092c8;
}
.h2d h2 {
  border-bottom: 1px dotted #0092c8;
}
.h2d a.dropdown-toggle {
  border: 1px solid #9ccbde;
}
.h2d ul.carrot > li:before,
.h2d ul.check > li:before,
.h2d ul.star > li:before {
  color: #0092c8;
}
.h2d .card-header {
  background: #252525;
  color: #ffc600;
}
.h2d .select-state {
  max-width: 400px;
}
.h2d .boxed-hover {
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
}
.h2d .boxed-hover:hover {
  box-shadow: 0 3px 10px rgba(27, 141, 222, 0.2);
}

#aside a.livechat:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e830";
  color: inherit;
  font-family: "icons";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}

/* LIVE CLASS */
#banner-wrap.live .selectstate {
  background: rgba(255, 255, 255, 0.85);
  padding: 20px;
  border-radius: 3px;
  border: 1px solid #c5e2f0;
}

.mapWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  width: 100%;
  min-height: 1450px;
  height: 100%;
}
.mapWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media only screen and (min-width: 56.25em) {
  .h2d #header-wrap {
    border-top: 3px solid #252525;
  }
  .h2d .mapWrapper {
    min-height: 1200px;
  }
}
@media (min-width: 992px) {
  .h2d #banner-wrap {
    background: #fff url(/images/h2d/banner-bg.jpg) no-repeat 100% 0%;
    background-size: contain;
  }
  .h2d #banner-wrap.alt {
    background: #fff url(/images/h2d/banner-bg-2.jpg) no-repeat 100% 0%;
    background-size: contain;
  }
  .h2d #banner-wrap.start-smart {
    background: #fff url(/images/h2d/banner-bg-ss.jpg) no-repeat 50% 35%;
    background-size: cover;
    min-height: 450px;
  }
  .h2d #banner-wrap.start-smart img {
    margin-top: 300px;
  }
  .h2d #banner-wrap h4.parent-title {
    margin-top: -40px;
  }
}
@media (min-width: 1080px) {
  .h2d .mapWrapper {
    min-height: 980px;
  }
}
@media (min-width: 1200px) {
  .h2d #banner-wrap img {
    margin-top: -45px;
  }
}