/* H2D */
$h2d-theme-primary: #0092c8 !default;
$h2d-theme-secondary: #ffc600 !default;
$h2d-theme-nav-primary: #252525 !default;
$h2d-theme-nav-secondary: #0092c8 !default;
$h2d-link-color: #0092c8 !default;

.h2d {
  #nav-wrap,
  #nav {
    background: $h2d-theme-nav-primary;
    a.is-active,
    a.submenu.is-active,
    a:hover,
    li:hover a.submenu,
    li:hover span.submenu,
    span.submenu.is-active,
    ul.submenu a:hover {
      background: $h2d-theme-primary;
    }
  }
  #menutoggle {
    background-color: $h2d-theme-nav-primary;
    &.is-active,
    &:active,
    &:hover {
      background-color: lighten($h2d-theme-nav-primary, 8%);
    }
  }
  #nav ul {
    background: lighten($h2d-theme-nav-primary, 8%);
  }
  #header #support a.login {
    background: $h2d-theme-nav-primary;
    &:hover {
      background: lighten($h2d-theme-nav-primary, 8%);
    }
  }
  #banner-wrap {
    background: #fff url(/images/h2d/banner-bg-sm.jpg) no-repeat;
    background-size: cover;
    &.alt {
      background: #fff url(/images/h2d/banner-bg-2-sm.jpg) no-repeat;
      background-size: cover;
    }
    &.start-smart {
      background: #fff url(/images/h2d/banner-bg-ss-sm.jpg) no-repeat 50% 50%;
      background-size: cover;
      min-height: 225px;
      img {
        margin-top: 30px;
      }
    }
    p {
      max-width: 300px;
      font-size: 1.6em;
      color: #333;
      line-height: 1.4em;
    }
    h4.parent-title {
      margin-top: -20px;
      color: #0f4b91;
      font-weight: 800;
      text-transform: uppercase;
    }
  }
  #sale-wrap {
    background: #0594c9;
    font-size: 1.2em;
  }
  #cta-wrap {
    background: $h2d-theme-nav-primary;
    h1 {
      border-bottom: 1px solid #ccc;
      color: #fff;
    }
    h2 {
      font-weight: bold;
      color: $h2d-theme-secondary;
      border-bottom: 0;
    }
    .price {
      color: $h2d-theme-secondary;
      span.only.from {
        color: #fff;
        font-size: 16px !important;
        font-weight: 400;
      }
      .member {
        display: inline-block;
        font-size: 16px;
        text-transform: uppercase;
        margin-left: 8px;
      }
    }
  }
  h1,
  h2,
  h3,
  h4 {
    color: $h2d-theme-primary;
  }
  h2 {
    border-bottom: 1px dotted $h2d-theme-primary;
  }
  a.dropdown-toggle {
    border: 1px solid #9ccbde;
  }
  ul.carrot > li:before,
  ul.check > li:before,
  ul.star > li:before {
    color: $h2d-theme-primary;
  }
  .card-header {
    background: $h2d-theme-nav-primary;
    color: $h2d-theme-secondary;
  }
  .select-state {
    max-width: 400px;
  }

  .boxed-hover {
    transition:
      box-shadow 0.15s ease-in-out,
      transform 0.15s ease-in-out;
    &:hover {
      box-shadow: 0 3px 10px rgba(27, 141, 222, 0.2);
    }
  }
}

#aside {
  a.livechat {
    &:before {
      display: inline-block;
      margin-right: 5px;
      width: inherit;
      height: inherit;
      content: '\e830';
      color: inherit;
      font-family: 'icons';
      font-weight: normal;
      text-align: center;
      font-size: 105%;
      vertical-align: top;
    }
  }
}

/* LIVE CLASS */
#banner-wrap.live {
  .selectstate {
    background: rgba(255, 255, 255, 0.85);
    padding: 20px;
    border-radius: 3px;
    border: 1px solid #c5e2f0;
  }
}
.mapWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  width: 100%;
  min-height: 1450px;
  height: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  .h2d {
    #header-wrap {
      border-top: 3px solid $h2d-theme-nav-primary;
    }
    .mapWrapper {
      min-height: 1200px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .h2d {
    #banner-wrap {
      background: #fff url(/images/h2d/banner-bg.jpg) no-repeat 100% 0%;
      background-size: contain;
      &.alt {
        background: #fff url(/images/h2d/banner-bg-2.jpg) no-repeat 100% 0%;
        background-size: contain;
      }
      &.start-smart {
        background: #fff url(/images/h2d/banner-bg-ss.jpg) no-repeat 50% 35%;
        background-size: cover;
        min-height: 450px;
        img {
          margin-top: 300px;
        }
      }
      h4.parent-title {
        margin-top: -40px;
      }
    }
  }
}

// Extra large devices (large desktops, 1080px and up)
@media (min-width: 1080px) {
  .h2d {
    .mapWrapper {
      min-height: 980px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .h2d {
    #banner-wrap {
      img {
        margin-top: -45px;
      }
    }
  }
}
